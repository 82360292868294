// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-our-company-js": () => import("./../../../src/pages/about-us/our-company.js" /* webpackChunkName: "component---src-pages-about-us-our-company-js" */),
  "component---src-pages-activities-index-js": () => import("./../../../src/pages/activities/index.js" /* webpackChunkName: "component---src-pages-activities-index-js" */),
  "component---src-pages-activities-strapi-activity-slug-js": () => import("./../../../src/pages/activities/{StrapiActivity.slug}.js" /* webpackChunkName: "component---src-pages-activities-strapi-activity-slug-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-strapi-article-slug-js": () => import("./../../../src/pages/blog/{StrapiArticle.slug}.js" /* webpackChunkName: "component---src-pages-blog-strapi-article-slug-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-destinations-index-js": () => import("./../../../src/pages/destinations/index.js" /* webpackChunkName: "component---src-pages-destinations-index-js" */),
  "component---src-pages-destinations-strapi-destination-slug-js": () => import("./../../../src/pages/destinations/{StrapiDestination.slug}.js" /* webpackChunkName: "component---src-pages-destinations-strapi-destination-slug-js" */),
  "component---src-pages-destinations-strapi-region-slug-js": () => import("./../../../src/pages/destinations/{StrapiRegion.slug}.js" /* webpackChunkName: "component---src-pages-destinations-strapi-region-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-private-js": () => import("./../../../src/pages/private.js" /* webpackChunkName: "component---src-pages-private-js" */),
  "component---src-pages-specialities-strapi-speciality-slug-js": () => import("./../../../src/pages/specialities/{StrapiSpeciality.slug}.js" /* webpackChunkName: "component---src-pages-specialities-strapi-speciality-slug-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-tours-index-js": () => import("./../../../src/pages/tours/index.js" /* webpackChunkName: "component---src-pages-tours-index-js" */),
  "component---src-pages-tours-strapi-tour-slug-js": () => import("./../../../src/pages/tours/{StrapiTour.slug}.js" /* webpackChunkName: "component---src-pages-tours-strapi-tour-slug-js" */),
  "component---src-pages-upcoming-rides-js": () => import("./../../../src/pages/upcoming-rides.js" /* webpackChunkName: "component---src-pages-upcoming-rides-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

